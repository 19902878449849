import { DataSource } from '@angular/cdk/collections';
import { ContributorInfoDto } from '@swagger/humanresources';
import { firstValueFrom, Observable } from 'rxjs';

export class UsersDataSource extends DataSource<ContributorInfoDto[]> {
  /** Stream of data that is provided to the table. */
  data$: Observable<ContributorInfoDto[] | undefined>;

  constructor(data: Observable<ContributorInfoDto[] | undefined>) {
    super();
    this.data$ = data;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): any {
    return this.data$;
  }

  disconnect() {}
}
