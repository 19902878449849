import { Params } from "@angular/router";
import { Role } from "@core/auth";

export class NavbarItem {
    key: string
    title?: string;
    icon: string;
    rounded?: boolean
    routerLink?: string;
    queryParams?: Params;
    active?: boolean;
    click?: (param?: string) => void;
    onNavbarForBreakpoint?: string[]; //if the item is placed directly on the navbar or inside the menu
    hiddenMobile?: boolean; //if the item is available on mobile
    hiddenTablet?: boolean; //if the item is available on tablet
    visibleForRole?: Role[];
    notVisibleForRole?:
    {
        mobile: Role[];
        tablet: Role[];
        desktop: Role[];
    };
    expandable?: boolean;
    expandableForRole?: Role[];
    notifications?: boolean;
    children?: NavbarItem[];
    position?: 'left' | 'right';
    i18n?: string;
}

export class NavbarItemBasic{
  title: string;
  icon?: string;
  routerLink?: string;
  queryParams?: Record<string, string>;
  active?: boolean;
  level?: number;
  expandable?: boolean;
}
